<template>
  <div>
    <headers />
    <div class="mb row" style="padding:15px;padding-top: 70px !important;">
      <div class="title">
        <span>
          {{data['Company_Name'] || ''}}
        </span>
        <div class="source">{{$language('source_speeda')}}</div>
      </div>
      
      <table class="table table-striped table-borderless">
        <tr v-for="(item,index) in data" :key="index">
          <td>{{$language(index + '_startup')}}</td>
          <td>
            <span v-if="index!='detail_url'">{{item}}</span>
            <a :href="item" v-if="index=='detail_url'" target="_black">{{item}}</a>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Headers from "@/components/Headers.vue";

export default {
  components: {
    Headers,
  },
  data() {
    return {
      id:'',
      isLoading:true,
      data:''
    }
  },
  mounted() {
    
    this.id = this.$route.query.id;
    this.request();
  },
  methods: {
    request(){
      axios.get('/v1/stock_company_info',{
        params:{
          id:this.id
        }
      }).then(res=>{
        this.isLoading = false
        let temp_data = res.data;
        this.data = temp_data.data;
      }).catch(res=>{
        console.log("失败",res);
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.source{
    width: 100%;
    text-align: right;
    flex: 1;
  text-align: right;
  color: rgb(204, 204, 204);
    font-size: 14px;

}
.title{
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 100px; */
    font-size: 23px;
    padding-left: 20px;
    span{
      flex: 1;
    }
}
.table-striped tr:nth-of-type(odd) {
    background-color: #f3f7f9;
}
</style>